<template>
  <div class="modal" style="display:block">

    <div class="modal-content">
        <p v-html="item.message"></p>
        <div class="visuel-dossier">
            <img
                :src="item.image"
                :alt="item.label">
        </div>
    </div>

    <div class="modal-footer">
        <button v-if="!isAlreadyInDossier && item.readonly === undefined" type="button" class="btn btn-blue align-center" @click="addToDossier()"><span>Ajouter au dossier médical</span></button>

        <button v-else type="button" class="btn btn-blue align-left" @click="close()"><span>Retour</span></button>
    </div>
  </div>
</template>

<script>
import jsonDossierMedical from '@/data/dossiermedical_objects.json'

export default {
  name: 'DossierMedicalViewer',
  props : [
    'options'
  ],
  computed: {
    item() {
      let res = jsonDossierMedical.find( r => {
          return r.label == this.options
      })
      return res
    },
    itemsInDossier() {
      return this.$store.state.dossiers.medical
    },
    isAlreadyInDossier() {
      return this.itemsInDossier.indexOf(this.options) !== -1
    }
  },
  methods : {
    close() {
      this.$emit('close')
    },
    addToDossier() {
      this.$store.commit("addToDossier", {dossier: 'medical', item: this.options})
      this.$emit('close')
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
